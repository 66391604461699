import React from "react"
import styled from "styled-components"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextBlock from "../components/TextBlock"

// Styling
import { fonts } from "../styles/fonts"
import { mq } from "../styles/utils/mq"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <BigTitle>NOT FOUND</BigTitle>
    <TextBlock
      text={"You just hit a route that doesn&#39;t exist... the sadness."}
    />
  </Layout>
)

export default NotFoundPage

const BigTitle = styled.h1`
  padding: 25px 5%;
  font-family: ${fonts.primary};
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 1px;

  ${mq.from.S`
    padding: 25px 15% 0;
  `}
`
